import styled from '@emotion/styled'
import axios from 'axios'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  const [response, setResponse] = useState<any>()

  useEffect(() => {
    axios
      .get(`https://www.iubenda.com/api/privacy-policy/55503386/no-markup`)
      .then((res) => {
        setResponse(res.data)
      })
  }, [])

  return (
    <Container>
      {title ? <Title className="wave">{title}</Title> : null}

      {response && response ? (
        <Description dangerouslySetInnerHTML={{ __html: response.content }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 7.5rem;
  padding: 0 12.639vw;

  @media (max-width: 1199px) {
    margin: 8.75rem auto 0;
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;

  @media (max-width: 1199px) {
    &::after {
      display: none !important;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryDark3};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark3};
        border-radius: 50%;
        position: absolute;
        top: 0.875rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 1.875rem;
  }
`
